<template>
  <base-section
    id="before-footer"
    style="background-color: #ffffff;"
    class="py-11 pb-5"
  >
      <v-container>
        <v-img
          :src="require(`@/assets/RH_ROBOTICS_logo.png`)"
          :max-width="$vuetify.breakpoint.mdAndUp ? 250 : 200"
        ></v-img>
        <br>
        <v-row>
          <v-col
            cols="8"
            sm="3"
          >
            <a
              href="/"
              style="color:black;"
            >HOME</a><br>
            <a
              href="/about"
              style="color:black;"
            >ABOUT US</a><br>
            <a
              href="/solutions"
              style="color:black;"
            >SOLUTIONS</a><br>
            <a
              href="/contact"
              style="color:black;"
            >CONTACT US</a>
          </v-col>
          <v-col
            cols="8"
            sm="3"
          >
            <h4>HEADQUARTERS</h4>
            <p>
              No. 9009 Qinglongshan Road,
              <br>255000, High tech Zone, Zibo City,
              <br>Shandong Province, China
            </p>
          </v-col>
          <v-col
            cols="8"
            sm="3"
          >
            <h4>SOUTH EAST ASIA OFFICE</h4>
            <p>
              LC1, B40202, Jalan Pantai Sinaran,
              <br>11700, Gelugor,
              <br>Pulau Pinang, Malaysia
            </p>
          </v-col>
          <v-col
            cols="8"
            sm="3"
          >
            <h4>EMAIL</h4>
            <p>
              lee.mg@ronghaorobot.com
            </p>
          </v-col>
        </v-row>
      </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionBeforeFooter',

    data: () => {
      return {
        stats: [
          'DESIGN',
          'ENGINEERING',
          'TECHNOLOGY',
          'INNOVATION',
        ],
      }
    },
  }
</script>
